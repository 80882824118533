// config.js

const bizIcon = "https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg";
const imageOne = "https://pix.idxre.com/pix/VADRRAOR/main/0/72400_0.jpg";
const imageTwo = "https://pix.idxre.com/pix/VADRRAOR/main/0/71260_0.jpg";
const imageThree = "https://pix.idxre.com/pix/VADRRAOR/main/0/71085_0.jpg";
const imageFour = "https://pix.idxre.com/pix/VADRRAOR/main/0/69956_0.jpg";
const imageFive = "https://pix.idxre.com/pix/VADRRAOR/main/0/72458_0.jpg";

export const siteConfig = {
  title: "Kelly Oakes | Realtor",
  metaDescription: "Kelly Oakes | Realtor",
  theme: {
    primaryColor: "blue-600",
    secondaryColor: "gray-900",
    accentColor: "text-blue-500"
  },
  logo: {
    src: bizIcon,
    alt: "Kelly Oakes | Realtor",
  },
  navigation: [
    { name: "Services", sectionId: "services" },
    { name: "About", sectionId: "about" },
    { name: "Location", sectionId: "location" },
  ],
  businessDetails: {
    name: "Kelly Oakes | Realtor",
    headline: "Your Real Estate Expert in Franklin Turnpike",
    address: "1113 Franklin Turnpike, Danville, VA, 24540",
    description: `
      Whether you’re buying, selling, or both, Kelly Oakes is committed to making your real estate journey a success. With professional expertise, local market knowledge, and a personalized approach, Kelly ensures every client receives tailored services designed to meet their unique needs. From expert advice to seamless execution, Kelly is your trusted partner for all things real estate in Danville, VA, and beyond.`,
  },
  images: {
    business: {
      src: imageOne,
      alt: "Your Trusted Real Estate Expert in Franklin Turnpike",
      width: 2432,
      height: 1442
    }
  },
  features: [
    {
      description: 
        "Buying or selling a home is a major decision, and Kelly is dedicated to making the process smooth and stress-free with thoughtful guidance every step of the way.",
    },
    {
      description: 
        "With extensive knowledge of the Danville area and local market trends, Kelly offers expert advice to help you find the perfect home or maximize the sale of your property.",
    },
    {
      description: 
        "Committed to exceeding expectations, Kelly ensures you feel supported, informed, and confident throughout your real estate journey.",
    },
  ],  
  services: {
    sectionTitle: "Listings Available",
    description: 
      "Whether you’re buying or selling, it’s ultimately about realizing a dream. Kelly Oakes is here to make the process smooth and hassle-free. Clients value her expertise, dedication to securing the best deals, and her friendly, approachable nature. Kelly is committed to helping you find the perfect property or buyer while making the journey enjoyable and rewarding.",
    callouts: [
      {
        name: '00 Deer Run Rd, Danville, VA 24540',
        description: 'Located in a peaceful area with spacious lots, this property offers the perfect setting for building your dream home amidst nature.',
        imageSrc: imageTwo,
        imageAlt: 'Property at 00 Deer Run Rd, Danville, VA 24540',
      },
      {
        name: '239 Mayfield Rd, Danville, VA 24541',
        description: 'A charming property with a blend of classic appeal and modern potential, ideal for families or first-time buyers.',
        imageSrc: imageThree,
        imageAlt: 'Property at 239 Mayfield Rd, Danville, VA 24541',
      },
      {
        name: '812 Wilson St, Danville, VA 24541',
        description: 'This conveniently located property offers quick access to schools, shopping, and local amenities, making it a perfect choice for a vibrant lifestyle.',
        imageSrc: imageFour,
        imageAlt: 'Property at 812 Wilson St, Danville, VA 24541',
      },
    ],
    otherServices: [
      "356 Hermitage Dr, Danville, VA 24541",
      "0000 Franklin Tpke, Danville, VA 24540",
      "190 Westhaven Dr, Danville, VA 24541",
      "318 Main St, Gretna, VA 24557",
    ],
  },
  about: {
    sectionTitle: "About Kelly Oakes | Realtor",
    description: 
      "Kelly Oakes brings exceptional expertise in Danville, VA real estate, providing clients with personalized service, local market knowledge, and a dedication to excellence. Whether you’re buying, selling, or investing, Kelly is committed to making the process smooth and rewarding, delivering a real estate experience that exceeds expectations.",
    image: {
      src: imageFive,
      alt: "Kelly Oakes, trusted Danville real estate expert",
      width: 1200,
      height: 800
    }
  },
  location: {
    sectionTitle: "Our Location",
    destinationAddress: "1113 Franklin Turnpike, Danville, VA 24540",
    googleMapsEmbedUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3168.1234567890123!2d-79.39692!3d36.638165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88531a1234567890%3A0xabcdef1234567890!2s1113+Franklin+Turnpike%2C+Danville%2C+VA+24540!5e0!3m2!1sen!2sus!4v1710407450828!5m2!1sen!2sus",
    subCategories: [
      {
        name: 'Apple Maps',
        url: 'https://maps.apple.com/?address=1113%20Franklin%20Turnpike,%20Danville,%20VA%2024540&ll=36.638165,-79.39692&q=1113%20Franklin%20Turnpike'
      },
      {
        name: 'Bing Maps',
        url: 'https://www.bing.com/maps?q=1113+Franklin+Turnpike,+Danville,+VA+24540&FORM=HDRSC6'
      },
      {
        name: 'Google Earth',
        url: 'https://earth.google.com/web/search/1113+Franklin+Turnpike,+Danville,+VA+24540/@36.638165,-79.39692,15a,814.57589625d,35y,0h,0t,0r'
      },
      {
        name: 'Google Maps',
        url: 'https://www.google.com/maps/place/1113+Franklin+Turnpike,+Danville,+VA+24540/@36.638165,-79.39692,15z'
      }
    ]
  },
  contact: {
    sectionTitle: "Ready to Find Your Dream Home?",
    description: "Contact Kelly Oakes | Realtor to discuss your real estate goals or schedule a consultation. Whether you’re buying, selling, or investing in the Danville area, Kelly is here to provide expert guidance and personalized support every step of the way.",
    socialLinks: {
      facebook: "https://www.kellyoakesrealtor.com/",     
      instagram: "https://www.kellyoakesrealtor.com/",     
      twitter: "https://www.kellyoakesrealtor.com/",     
      linkedin: "https://www.kellyoakesrealtor.com/",     
      youtube: "https://www.kellyoakesrealtor.com/",     
    },
    contactNumbers: [
      "Mobile: (434) 489-8023",
    ],
    logo: bizIcon,
    emailRecipient: "koakesmk@yahoo.com"
  },
  footer: {
    bizSolutionsLogo: "https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg"
  }
};
